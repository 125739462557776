// frontend/src/hooks/UserCheckIn.js
import { useState, useEffect } from "react";
// import axios from 'axios';
import axios from '../axios';

const useUserCheckIn = () => {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;

  const [isClicked, setIsClicked] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    // 로컬 스토리지에서 토큰을 검색하고 상태를 업데이트합니다.
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);
  
  // // 로그인 기능 구현하면 위에 로직으로 변경
  // const token = process.env.REACT_APP_JWT_USER_TOKEN;

  const sendCheckInReq = async (userId) => {
    try {
      if (token) {
        // 관리자만 해당 기능 수행 가능
        const response = await axios.post(`${serverUrl}users/enter/admin/${userId}`, {}, { 
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        //console.log(response.data);
        alert(response.data.message);

        if (response.status === 200 && response.data.message === "DONE! You have signed in today") {
          setIsClicked(true);
          return true;
        }
      }
    } catch (error) {
      console.error('Error sending check-in:', error);
    }
    return false;
  };

  // return { sendCheckInReq, isClicked, error };
  return { sendCheckInReq, isClicked };
};

export default useUserCheckIn;
