// frontend/src/Components/KakaoCallback.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../axios';

function KakaoCallback() {
  const navigate = useNavigate();

  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const url = new URL(window.location.href);
  const authCode = url.searchParams.get('code');
  const [requestSent, setRequestSent] = useState(false);
  // console.log('authCode:', authCode);

  useEffect(() => {
    
    // TODO: 여기서 authCode를 사용하여 백엔드 서버에 인증 요청을 보내고 처리합니다.
    // 예: yourBackendServer/auth/kakao?code=authCode
    const fetchData = async () => {
      if (authCode && !requestSent) {      
        setRequestSent(true);
        try {
          // console.log('authCode2:', authCode);
          // console.log(`${serverUrl}auth/kakao?code=${authCode}`);
          const response = await axios.get(`${serverUrl}users/auth/kakao?code=${authCode}`) // ...
          // console.log(response.data);

          
          if (response.data && response.data.token) {
            localStorage.setItem('token', response.data.token); // 로컬스토리지에 token 저장
            localStorage.setItem('userId', response.data._id); // 로컬스토리지에 userId 저장

            // 인증 처리 후 리디렉션
            navigate('/overview');
          }
          
        } catch (error) {
          console.error('Error kakao login:', error);
        }
      }
    };

    fetchData();
  }, [navigate, authCode]);

  return (
    <div>Loading...</div>
  );
}

export default KakaoCallback;
