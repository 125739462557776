import React from "react";
// import user from "../images/dp.jpg";
import user from "../images/logo-default2.png";

import useGetUserInfo from '../hooks/GetUserInfo';
import Body from "../pages/Body";

function Header() {
  // 로그인 시 생성된 값들 가져오기
  const storedToken = localStorage.getItem('token');
  // const storedUserId = localStorage.getItem('userId'); 

  // 훅으로 /users/:Id
  const { userName, userImageUrl } = useGetUserInfo(storedToken);
  // console.log('userImageUrl:', userImageUrl);


  return (
    <div>
      <div className="flex flex-align header">
        <img className="user-pic" src={userImageUrl  || user} alt="User"></img>
        <div>
          {/* {storedToken && storedUserId ? */}
          {storedToken ?
            <React.Fragment>
              <p className="greet">Hello {userName}!👋🏻</p>
              <p className="gray">We hope you're having a great day.</p>
            </React.Fragment>
            :
            <React.Fragment>
              <p className="greet">Hello Guest!👋🏻</p>
              <p className="gray">Please log in to see your profile.</p>
            </React.Fragment>
          }
        </div>
      </div>
      <Body />
    </div>
  );

}

export default Header;

