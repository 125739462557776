// frontend/src/hooks/UserLogin.js
import { useState } from "react";
// import axios from 'axios';
import axios from '../axios';

const useUserLogin = () => {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);

  const login = async (email, password) => {
    setError(null);
    try {
      // console.log('email:', email);
      // console.log('password:', password);
      const response = await axios.post(`${serverUrl}users/login`, {
        email,
        password
      });

      if (response.data && response.data.token) {
        setToken(response.data.token);
        localStorage.setItem('token', response.data.token); // 로컬스토리지에 token 저장
        localStorage.setItem('userId', response.data._id); // 로컬스토리지에 userId 저장
      }
    } catch (err) {
        setError(err.response ? err.response.data : 'Login failed.');
    }
  };

  return { login, token, error };
};

export default useUserLogin;

