// frontend/src/hooks/GetDepInfo.js
import { useState, useEffect } from "react";
import axios from '../axios';

const useGetDepInfo = () => {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const [departments, setDepartments] = useState([]);
  
  useEffect(() => {
    const fetchDepartments  = async () => {
      try {      
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${serverUrl}departments`, {
          headers: {
            'Authorization': `Bearer ${storedToken}`
          }
        });
        // console.log('fetchDepartments:', response.data)

        if (response.status === 200) {
          setDepartments(response.data);
        }

      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };

  fetchDepartments();
  }, [serverUrl]);

  return departments;
};

export default useGetDepInfo;
