import React, { useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

// const WS_URL = 'ws://183.111.236.249:5000';
const WS_URL = 'wss://miroslav.cloud249.com/socket';


function Front1() {
  const { sendJsonMessage, readyState } = useWebSocket(WS_URL, {
    onOpen: () => {
      console.log('WebSocket connection established.');
    },
    share: true,
    filter: () => false,
    retryOnError: true,
    shouldReconnect: () => true
  });
  
  
  const handleClick = () => {  
    console.log('readyState:', readyState);
    // console.log(ReadyState); // {0: 'CONNECTING', 1: 'OPEN', 2: 'CLOSING', 3: 'CLOSED', UNINSTANTIATED: -1, -1: 'UNINSTANTIATED', CONNECTING: 0, OPEN: 1, CLOSING: 2, …}
    console.log(ReadyState.OPEN);
    // console.log(ReadyState);
    if(readyState === ReadyState.OPEN) {
      sendJsonMessage({ type: 'buttonClick' });
    }
  }

  // useEffect(() => {
  //   console.log('ReadyState:', ReadyState);
  //   if(readyState === ReadyState.OPEN) {
  //     sendJsonMessage({
  //       type: 'userevent'
  //     });
  //   }
  // }, [sendJsonMessage, readyState]);

  return (
    <div>
      <button onClick={handleClick}>click</button>
    </div>
  );
}

export default Front1;
