import { useState, useEffect } from "react";
import axios from '../axios';

// data load using mongodb
const useFetchData = (url) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  // const token = process.env.REACT_APP_JWT_USER_TOKEN;
  const [token, setToken] = useState(null);

  useEffect(() => {
    // 로컬 스토리지에서 토큰을 검색하고 상태를 업데이트합니다.
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  //console.log('token:', token);
  useEffect(() => {
    if (token) {
      axios.get(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        setData(response.data);
        //setFilteredData(response.data);
      })
      .catch(error => {
        //console.error('Error fetching data: ', error
        setError(error);
      });
    }
  }, [url, token]);

  return { data, error };
};

export default useFetchData;
