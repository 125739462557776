// 개개인이 아닌 관리자 계정으로 출/퇴근 요청 할 수 있도록 변경
import React, { useState, useEffect } from "react";
import useWebSocket, { ReadyState } from 'react-use-websocket';

import useFetchData from "../hooks/DataFetch";
import useUserCheckIn from "../hooks/UserCheckIn";
import useUserCheckOut from "../hooks/UserCheckOut";
import useCheckAttendance from '../hooks/CheckAttendance';

import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";


function Attendance() {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const WS_URL = process.env.REACT_APP_WEBSOCKET_URL;

  const { data, error } = useFetchData(`${serverUrl}users`);
  const [filteredData, setFilteredData] = useState(data);
  const [searchText, setSearchText] = useState("");
  const [usersMap, setUsersMap] = useState({});

  const { sendCheckInReq } = useUserCheckIn();
  const { sendCheckOutReq } = useUserCheckOut();

  const attendanceStatus = useCheckAttendance();
  const [checkInUserBtn, setCheckInUserBtn] = useState(new Set());

  const options = {
    // onOpen: () => {
    //   console.log('WebSocket connection established.');
    // },
    share: true,
    // filter: () => false,
    retryOnError: true,
    shouldReconnect: () => true
  }
  const { sendJsonMessage, readyState } = useWebSocket(WS_URL, options);

  // load mongo data "using DataFetch.js hooks"
  useEffect(() => {
    if (data) {
      const map = {};
      data.forEach(user => {
        map[user._id] = user.name;
      });
      setUsersMap(map);
      setFilteredData(data);
    }
  }, [data]);
  // console.log('data:', data);
  // console.log('filteredData:', filteredData);

  // 출퇴근 상태에 따른 버튼 상태 업데이트
  useEffect(() => {
    if (attendanceStatus) {
      //console.log('Attendance Compo - attendanceStatus:', attendanceStatus);
      const newCheckInUserBtn = new Set(
        attendanceStatus.map(user => user)
      );

      setCheckInUserBtn(newCheckInUserBtn);
      console.log('clicked Buttons:', checkInUserBtn);
    }
  }, [attendanceStatus]);
  
  // 밑에 onClick 함수만 수정함 (매개변수에 대한 추가 수정은 살펴봐야함)
  const handleToggleAttendance = async (userId, index) => {
    console.log('btn click:', checkInUserBtn);

    const userName = usersMap[userId];
    const isCheckingIn = !checkInUserBtn.has(userId);
    const action = isCheckingIn ? sendCheckInReq : sendCheckOutReq;
    // console.log('isCheckingIn:', isCheckingIn);
    // console.log('action:', action);

    const result = await action(userId);

    if (isCheckingIn) {
      setCheckInUserBtn(prev => new Set(prev).add(userId));

      if (result && readyState === ReadyState.OPEN) {
        sendJsonMessage({
          type: 'buttonClick',
          content:  userName
        });
      }
    } else {
      setCheckInUserBtn(prev => new Set([...prev].filter(id => id !== userId)));
    }

  };


  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <Navbar />
      <div className=" flex justify-center flex-align search-div">
        <input
          className="search"
          type="text"
          placeholder="Search your name here"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        ></input>
        <button
          className="search-btn"
          onClick={() => {
            // console.log(searchText);
            const filteredData = data.filter((res) =>
              res.name.includes(searchText)
            );
            setFilteredData(filteredData);
          }}
        >
          Search
        </button>
      </div>
      <b className="flex justify-center black">Staff List</b>
      <div className="attendance-table">
        <table>
          <tbody>
            <tr>
              <th className="th">No</th>
              <th className="th">Name</th>
              <th className="th">E-mail</th>
              <th className="th">Mark your attendance here</th>
            </tr>

            {filteredData.map((res, index) => (
              <tr key={res._id}>
                <td>{index + 1}</td>
                <td>{res.name}</td>
                <td>{res.email}</td>
                <td>
                  <button
                    // className={activeButton === index ? "active" : "btn-attendance"}
                    className={
                      checkInUserBtn.has(res._id)
                        ? "active" // 버튼 활성화 상태의 CSS 클래스
                        : "btn-attendance" // 버튼 비활성화 상태의 CSS 클래스
                    }
                    onClick={() => handleToggleAttendance(res._id, index)}
                  >
                    {/* {res.attendance ? "Present Marked" : "Mark Attendance"} */}
                    {checkInUserBtn.has(res._id) ? "Check Out" : "Check In"}
                    
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Footer />
    </div>
  );
}

export default Attendance;
