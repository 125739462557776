// lineChart - 최근 일주일 일별 출근한 사람수
// pieChart - 직원 성별 비율
// barChart - 전직원의 월별 출근률
// table - 직원별 직전월 출근률 top 5, 직전월 당월 출근률 동시에 보이게
import React from "react";
import Card from "../Components/Card";
import ExportCSV from "../Components/ExportCSV";

import useFetchData from "../hooks/DataFetch";

import BarChart from "../Components/BarChart";
import PieChart from "../Components/PieChart";
import LineChart from "../Components/LineChart";
import TopAttendanceTable from "../Components/TopAttendanceTable";
import AttendanceTimeTable from '../Components/AttendanceTimeTable';

function Body() {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const { data: userData, error: userError } = useFetchData(`${serverUrl}users`);
  const { data: attendanceData, error: attendanceError } = useFetchData(`${serverUrl}history`);
  const today = new Date().toISOString().split('T')[0]; // YYYY-MM-DD

  // 날짜 처리 함수
  const getCurrentPreviousMonth = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // 0-11
    const currentYear = currentDate.getFullYear();

    let previousMonth, previousYear;
    if (currentMonth === 0) {
      previousMonth = 11;
      previousYear = currentYear - 1;
    } else {
      previousMonth = currentMonth -1;
      previousYear = currentYear;
    }

    return { currentMonth, currentYear, previousMonth, previousYear };
  };

  const isLate = (entryTime) => {
    const entryDate = new Date(entryTime);
    const nineAM = new Date(entryDate);
    nineAM.setHours(9, 0, 0, 0);
    // nineAM.setHours(12, 18, 0, 0);
    return entryDate > nineAM;
  };

  const isEarly = (entryTime) => {
    const entryDate = new Date(entryTime);
    const nineAM = new Date(entryDate);
    nineAM.setHours(9, 0, 0, 0);
    // nineAM.setHours(12, 18, 0, 0);
    return entryDate <= nineAM;
  };

  // card
  const handleLatestAttendance = () => {
    if (attendanceData && attendanceData.length > 0) {
      const latestAttendance = attendanceData[attendanceData.length - 1];

      const totalStaff = latestAttendance.employees.length;
      const presentStaff = latestAttendance.employees.filter(em => em.attended).length;
      const absentStaff = totalStaff - presentStaff;

      return { totalStaff, presentStaff, absentStaff };
    }
    return { totalStaff: 0, presentStaff: 0, absentStaff: 0 };
  }

  // lineChart
  const handleDailyAttendance = () => {
    const attendanceByDate = {};

    // 최근 5일(일주일)
    // const recentAttendacneData = [...attendanceData].sort((a, b) => new Date(b.date) - new Date(a.date)).slice(0, 6);
    const recentAttendacneData = attendanceData.slice(Math.max(attendanceData.length - 8, 0));
    recentAttendacneData.forEach(record => {
      const date = new Date(record.date).toISOString().split('T')[0]; // YYYY-MM-DD

      // 초기화
      if (!attendanceByDate[date]) {
        attendanceByDate[date] = 0;
      }

      record.employees.forEach(emp => {
        if (emp.attended) {
          attendanceByDate[date]++;
        }
      });
    });

    const dates = Object.keys(attendanceByDate).sort();
    const attendanceCounts = dates.map(date => attendanceByDate[date]);

    return { dates, attendanceCounts };
  }
  
  // pieChart
  const handleGenderData = () => {
    let maleCount = 0;
    let femaleCount = 0;
    let otherCount = 0;
  
    userData.forEach(user => {
      if (user.gender === 1) {
        maleCount++;
      } else if (user.gender === 2) {
        femaleCount++;
      } else {
        otherCount++;
      }
  
    });
    return { maleCount, femaleCount, otherCount};
  }

  // table (top 5 attendance rate)
  const handleStaffAttendanceRate = () => {
    const { currentMonth, currentYear, previousMonth, previousYear } = getCurrentPreviousMonth();
    const staffAttendance ={};

    attendanceData.forEach(record => {
      const recordDate = new Date(record.date);
      const month = recordDate.getMonth(); // 0-11
      const year = recordDate.getFullYear();

      record.employees.forEach(emp => {
        // 직원 삭제한 경우도 있으니 해당 직원이 있을 경우만 아래 로직 실행
        if (emp.employee && emp.employee._id) {
          if (!staffAttendance[emp.employee._id]) {
            staffAttendance[emp.employee._id] = {
              name: emp.employee.name,
              previousMonthAttendance: 0,
              currentMonthAttendance: 0,
              totalDaysPrevious: 0,
              totalDaysCurrent: 0
            };
          }

          if (year === previousYear && month === previousMonth) {
            staffAttendance[emp.employee._id].totalDaysPrevious += 1;
            if (emp.attended) {
              staffAttendance[emp.employee._id].previousMonthAttendance += 1;
            }
          }

          if (year === currentYear && month === currentMonth) {
            staffAttendance[emp.employee._id].totalDaysCurrent += 1;
            if (emp.attended) {
              staffAttendance[emp.employee._id].currentMonthAttendance += 1;
            }
          }

        } // if
      }); // record.forEach
    }); // attendanceData.forEach

    const staffAttendanceRate = Object.values(staffAttendance)
      .map(staff => ({
        ...staff,
        previousMonthPercentage: (staff.previousMonthAttendance / staff.totalDaysPrevious * 100).toFixed(0),
        currentMonthPercentage: (staff.currentMonthAttendance / staff.totalDaysCurrent * 100).toFixed(0)
      }))
      .sort((a, b) => b.previousMonthPercentage - a.previousMonthPercentage)
      .slice(0, 5); // top 5

    return staffAttendanceRate;
  };

  // barChart
  const handleAttendanceRate = () => {
    const monthlyAttendance = {};
  
    attendanceData.forEach(record => {
      const date = new Date(record.date);
      const monthYear = date.toLocaleDateString('en-US', { year: 'numeric', month: 'short' }); // ex. 'Jan 2023'
  
      if (!monthlyAttendance[monthYear]) {
        monthlyAttendance[monthYear] = { attendedDays: 0, totalDays: 0 };
      }
  
      record.employees.forEach(emp => {
        monthlyAttendance[monthYear].totalDays += 1;
        if (emp.attended) {
          monthlyAttendance[monthYear].attendedDays += 1;
        }
      });
    });
    // console.log('monthlyAttendance:', monthlyAttendance);
  
    const attendanceRates = Object.keys(monthlyAttendance).map(month => {
      const { attendedDays, totalDays } = monthlyAttendance[month];
      return {
        month,
        rate: (attendedDays / totalDays) * 100
      };
    });
  
    return attendanceRates;
  };

  // table (late/absent)
  const sortedUserData = [...userData]
    .map(user => {
      const lastAttendance = user.attendance[user.attendance.length - 1];
      const lastEntry = lastAttendance ? lastAttendance.entry : null;
      const isToday = lastEntry && new Date(lastEntry).toISOString().split('T')[0] === today;
      
      return {
        ...user,
        lastEntry: isToday ? lastEntry : 'No Check In',
        isLate: isToday && isLate(lastEntry),
        isEarly: isToday && isEarly(lastEntry)
      };
    })
    .filter(user => user.isLate || user.lastEntry === 'No Check In') // late or absent
    .sort((a, b) => {
      if (a.lastEntry === 'No Check In' && b.lastEntry === 'No Check In') {  
        return 0;  // 순서 변경 없음
      } else if (a.lastEntry === 'No Check In') {
        return 1; // a가 결석한 사람이면 b가 앞으로
      } else if (b.lastEntry === 'No Check In') {
        return -1; // b가 결석한 사람이면 a가 앞으로
      }
      return new Date(b.lastEntry) - new Date(a.lastEntry) // 나머지는 내림차순 정렬
    });
  // console.log('sortedUserData:', sortedUserData)

  const { totalStaff, presentStaff, absentStaff } = handleLatestAttendance();
  const { dates, attendanceCounts } = handleDailyAttendance();
  const { maleCount, femaleCount, otherCount } = handleGenderData();
  const staffAttendanceRate = handleStaffAttendanceRate();
  const attendanceRates = handleAttendanceRate();

  if (userError || attendanceError) {
    return <div>Error: {userError ? userError.message : attendanceError.message}</div>;
  }

  return (
    <div className="body">

      <div className="flex bgcolor justify-center">
        <Card img="./user.png" num={totalStaff} str="TOTAL STAFF" />
        <Card img="./tick.png" num={presentStaff} str="PRESENT TODAY" />
        <Card img="./cross.png" num={absentStaff} str="ABSENT TODAY" />
      </div>

      <div className="graphs flex justify-center flex-align">
        <div className="text-align">
          <LineChart dates={dates} attendanceCounts={attendanceCounts} />
        </div>
        <div className="pie">
          <PieChart male={maleCount} female={femaleCount} other={otherCount} />
        </div>
      </div>

      <div className="flex flex-align justify-center body-bottom">
        <TopAttendanceTable absentees={staffAttendanceRate} />

        <div>
          <BarChart attendanceRates={attendanceRates} />
        </div>
      </div>

      <div className="flex justify-center black">      
          <AttendanceTimeTable userData={sortedUserData} />
      </div>

      <div className="flex flex-align justify-center">
        <ExportCSV 
          data={sortedUserData} 
          filename="AttendanceReport"
          columns={['name', 'lastEntry']}
          label="Export CSV"
        />
      </div>

    </div> // body
  );
}

export default Body;

